const createMessage = (type, language) => {
    const messages = {
        success: { es: 'Gracias por unirte a nuestra comunidad Valtec!', en: 'Thank you for joining our Valtec community!' },
        error: { es: 'Hubo un error', en: 'There was a mistake' }
    };
    const classes = { success: 'success-message', error: 'error-message' };

    const currentMessage = messages[type][language];
    const currentClass = classes[type];

    const messageNode = document.createElement('div');
    messageNode.innerText = currentMessage;
    messageNode.classList.add(currentClass);

    return messageNode;
};

const createLoading = () => {
    const loadingNode = document.createElement('div');
    loadingNode.classList.add('spinner');
}

const formsSendMail = document.getElementsByClassName('send-mail-form');
var loading = false;

for (let i = 0; i <= formsSendMail.length - 1; i++) {
    const formSendMail = formsSendMail[i];
    formSendMail.addEventListener('submit', (e) => {
        e.preventDefault();

        if (loading) return;

        formSendMail.classList.add('loading');
        loading = true;


        const email = e.target["email"].value;
        const language =
            window.location.pathname.split("/")[1] === "en" ? "en" : "es";
        const url = window.location.href;
        sendEmailFooter(email, language, url).then(() => {
            formSendMail.classList.remove('loading');
            loading = false;
            formSendMail.append(createMessage('success', language));
            e.target["email"].value = '';
            setTimeout(() => {

                formSendMail.getElementsByClassName('success-message')[0].remove();
            }, 2000);
        }).catch(() => {
            formSendMail.classList.remove('loading');
            loading = false;
            formSendMail.append(createMessage('error', language));
            e.target["email"].value = '';
            setTimeout(() => {
                formSendMail.getElementsByClassName('error-message')[0].remove();
            }, 2000);
        });
    });
}





const sendEmailFooter = async(email, language, url) => {
    if (!axios) {
        return;
    }
    try {
        const language =
        window.location.pathname.split("/")[1] === "en" ? "en" : "es";
    const href = window.location.href;
    const keySection = getKeySection(window.location.pathname);

        let form = JSON.stringify({
            names,
            email,
            phone,
            interested,
            comment,
            section: sectionsContact[keySection],
            url: href,
            organization: company,
            type: 3,
            language,
        });

        await axios 
        .post(url, form, {
            headers: {
                "content-type": "application/json",
            },
        })
        .then((response) => console.log(response))
        .catch((error) => console.log(error.response));

    } catch (ex) {
        throw ex;
    }
};
